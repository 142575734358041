import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { setError as globalSetError } from '@/store/setError';

const {
  getChargePost,
  postChargePost,
  putChargePost,
  putChargePostStatus,
  deleteChargePost,
} = backOffice.chargePosts;

export const scopes = {
  detail: 'detail',
  newChargePost: 'newChargePost',
  deleteChargePost: 'deleteChargePost',
};

const chargePosts = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  state: {
    [scopes.detail]: {
      data: null,
    },
  },
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;
      if (scope === scopes.deleteChargePost) {
        globalSetError.bind(this)(state, payload);
      } else {
        state[scope].error = value;
      }
    },
    resetData(state) {
      state[scopes.newChargePost].data = null;
    },
    clearErrors(state) {
      state[scopes.newChargePost].error = null;
      state[scopes.newChargePost].STATUS = { ...INITIAL_STATUS };
    },
  },
  actions: {
    async putChargePost({ commit }, { chargePostUuid, data }) {
      await runAsyncFlow(commit, {
        request: putChargePost,
        params: [chargePostUuid, data],
        scope: scopes.newChargePost,
      });
    },
    async putChargePostStatus({ commit }, { chargePostUuid, data }) {
      await runAsyncFlow(commit, {
        request: putChargePostStatus,
        params: [chargePostUuid, data],
        scope: scopes.newChargePost,
      });
    },
    async postChargePost({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postChargePost,
        params: [data],
        scope: scopes.newChargePost,
      });
    },
    async getChargePost({ commit }, chargePostUuid) {
      await runAsyncFlow(commit, {
        request: getChargePost,
        params: [chargePostUuid],
        scope: scopes.detail,
      });
    },
    async deleteChargePost({ commit }, chargePostUuid) {
      await runAsyncFlow(commit, {
        request: deleteChargePost,
        params: [chargePostUuid],
        scope: scopes.deleteChargePost,
      });
    },
  },
})));

export default chargePosts;
